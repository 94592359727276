class Sliders {
    constructor(config) {
        if (config.sliders.hero) {
            this.hero();
        }
    }

    hero() {
        var $status = $('.paging__info');
        var $slickElement = $('.js-hero-slider');

        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            if (!slick.$dots) {
                return;
            }

            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + ' / ' + (slick.$dots[0].children.length));
        });


        $('.js-hero-slider').slick({
            infinite: false,
            speed: 1000,
            lazyLoad: 'ondemand',
            arrows: true,
            autoplay: true,
            pauseOnHover: false,
            draggable: true,
            rows: 0,
            dots: true,
            slidesToShow: 1,
            autoplaySpeed: 7000,
            prevArrow: $('.prev-slide'),
            nextArrow: $('.next-slide')
        });
    }
}

export {Sliders};