var customTimeout = null;
function bindCustomFilters() {
    $('section[data-cms="custom-filters"] [data-field]').not('input[type="checkbox"], [data-field="search"]').on('change', function () {
        filterUpdate($(this).closest('section[data-cms="custom-filters"]'), 1);
    });
    $('section[data-cms="custom-filters"] [data-field][type="checkbox"]').on('change', function () {
        var parent = $(this).closest('section[data-cms="custom-filters"]');
        clearTimeout(customTimeout);
        customTimeout = setTimeout(function () {
            filterUpdate(parent, 1);
        }, parent.data('timeout') ? parent.data('timeout') : 200);
    });
    $('section[data-cms="custom-filters"] [data-field="search"]').on('keyup', function () {
        var parent = $(this).closest('section[data-cms="custom-filters"]');
        clearTimeout(customTimeout);
        customTimeout = setTimeout(function () {
            filterUpdate(parent, 1, true);
        }, parent.data('timeout') ? parent.data('timeout') : 200);
    });
    $('section[data-cms="custom-pagination"] a').off('click').on('click', function (event) {
        event.preventDefault();
        filterUpdate($(this).closest('section[data-cms="custom-pagination"]'), $(this).data('page'));
    });
}
function unbindCustomFilters() {
    $('section[data-cms="custom-filters"] [data-field]').off('change');
}

var loading = false;
var lastWasSearch = false;
function filterUpdate(parent, page, searching, append, trigger) {
    if (loading) {
        return;
    }
    loading = true;

    unbindCustomFilters();

    var sectionItems = $('section[data-cms="custom"][data-module="' + $(parent).data('module') + '"]');
    var sectionFilter = $('section[data-cms="custom-filters"]');
    var sectionFilters = $('section[data-cms="custom-filters"] [data-field]');

    var filterValues = [];
    var search = '';
    var sorting = false;
    lastWasSearch = typeof searching !== 'undefined' ? searching : false;
    sectionFilters.each(function () {
        if ($(this).is('select')) {
            if ($(this).val()) {
                if ($(this).data('type')) {
                    if (!($(this).data('field') in filterValues)) {
                        filterValues[$(this).data('field')] = {};
                    }
                    filterValues[$(this).data('field')][$(this).data('type')] = $(this).val();
                }
                else {
                    filterValues[$(this).data('field')] = $(this).val();
                }
            }
        }
        else if ($(this).is("input[name='sorting'][type='radio']:checked")) {
            if ($(this).val()) {
                sorting = $(this).data('field');
            }
        }
        else if ($(this).is("input[type='search']")) {
            if ($(this).val()) {
                search = $(this).val();
            }
        }
        else if ($(this).is("input[type='checkbox']:checked")) {
            if (!($(this).data('field') in filterValues)) {
                filterValues[$(this).data('field')] = [];
            }
            filterValues[$(this).data('field')].push($(this).val());
        }
        else if ($(this).is("input[type='number']")) {
            if ($(this).val()) {
                if ($(this).data('type')) {
                    if (!($(this).data('field') in filterValues)) {
                        filterValues[$(this).data('field')] = {};
                    }
                    filterValues[$(this).data('field')][$(this).data('type')] = $(this).val();
                }
                else {
                    filterValues[$(this).data('field')] = $(this).val();
                }
            }
        }
        else if ($(this).is("input[type='radio']:checked")) {
            if ($(this).val()) {
                filterValues[$(this).data('field')] = [$(this).val()];
                console.log(filterValues);
            }
        }
    });

    sectionItems.each(function (index, sectionItem) {
        if (!($(sectionItem).data('no-pages') ? $(sectionItem).data('no-pages') : false) || ($(parent).data('cms') !== 'custom-pagination')) {
            $.ajax({
                type: "GET",
                data: {
                    'language': $(parent).data('language'),
                    'module': $(parent).data('module'),
                    'menu': $(parent).data('menu') ? $(parent).data('menu') : false,
                    'categories': $(sectionItem).data('categories'),
                    'filterCategories': $(sectionFilter).data('categories'),
                    'filterValues': filterValues.length ? filterValues : false,
                    'templateOverview': $(sectionItem).data('template-overview'),
                    'templateItem': $(sectionItem).data('template-item'),
                    'search': search,
                    'sorting': sorting,
                    'page': page,
                    'noPages': $(sectionItem).data('no-pages') ? $(sectionItem).data('no-pages') : false,
                    'first': index === 0
                },
                dataType: 'json'
            }).done(function (json) {
                if (json.success) {
                    if (append) {
                        $(sectionItem).append(json.items);
                        if (json.pagination.page === json.pagination.last) {
                            $(trigger).hide();
                        }
                    } else {
                        $(sectionItem).html(json.items);
                    }
                    let categoriesStr = $(parent).data('categories') ? '[data-categories="[' + $(parent).data('categories').join(',') + ']"]' : '';

                    $('section[data-cms="custom-pagination"][data-module="' + $(parent).data('module') + '"]' + categoriesStr).html(json.pagination.items);
                    if (json.filters) {
                        $('section[data-cms="custom-filters"][data-module="' + $(parent).data('module') + '"]').html(json.filters);
                        bindCustomFilters();
                        window.syvEvent.fire('custom.filters::updated');
                    } else {
                        bindCustomFilters();
                    }
                    if (lastWasSearch) {
                        var searchInput = $('section[data-cms="custom-filters"] [data-field="search"]');
                        searchInput.focus();
                        searchInput.val(searchInput.val());
                    }
                    window.syvEvent.fire('custom.items::updated(module:' + $(parent).data('module') + ')');
                }
            }).always(function () {
                loading = false;
            });
        }
    });
}

$(document).ready(function () {
    bindCustomFilters();
    window.syvEvent.fire('custom.filters::initialised');
});