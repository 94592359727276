export default {
    modules: {
        shop: {
            filtersEnabled: false
        }
    },

    sliders: {
        hero: true,
        caseslider: false,
        blocks: false
    }
}
