/**
 * PLEASE DO NOT MODIFY THIS FILE!
 *
 * This is the default config.
 * Settings can be overridden in the config.js file.
 */

export default {
    breakpoints: {
        "xs": 480,
        "sm": 768,
        "md": 992,
        "lg": 1200
    },

    googleRecaptcha: true,
    searchToggle: false,

    sliders: {
        hero: true,
        blocks: true
    },

    floatingLabels: [
        '.inputfield input'
    ],

    modules: {
    }
}
