$(function () {


    // CONFIG
    let visibilityIds = ['#counters_1']; //must be an array, could have only one element
    let counterClass = '.counter';
    let defaultSpeed = 3000; //default value

    // END CONFIG

    //init if it becomes visible by scrolling
    $(window).on('scroll', function () {
        getVisibilityStatus();
    });

    //init if it's visible by page loading
    getVisibilityStatus();

    function getVisibilityStatus() {
        let elValFromTop = [];
        let windowHeight = $(window).height(),
            windowScrollValFromTop = $(window).scrollTop();

        visibilityIds.forEach(function (item, index) { //Call each class\
            try { //avoid error if class not exist
                elValFromTop[index] = Math.ceil($(item).offset().top);
            } catch (err) {
                return;
            }
            // if the sum of the window height and scroll distance from the top is greater than the target element's distance from the top,
            //it should be in view and the event should fire, otherwise reverse any previously applied methods
            if ((windowHeight + windowScrollValFromTop) > elValFromTop[index]) {
                counter_init(item);
            }
        });
    }

    function counter_init(groupId) {
        let num, speed, direction, index = 0;
        $(counterClass).each(function () {
            num = $(this).attr('data-TargetNum');
            speed = $(this).attr('data-Speed');
            direction = $(this).attr('data-Direction');

            if (speed == undefined) speed = defaultSpeed;
            $(this).addClass('c_' + index); //add a class to recognize each counter
            doCount(num, index, speed, groupId, direction);
            index++;
        });
    }

    function doCount(num, index, speed, groupClass, direction) {
        let className = groupClass + ' ' + counterClass + '.' + 'c_' + index;
        $(className).animate({
            num
        }, {
            duration: +speed,
            easing: 'swing',
            step: function (now) {
                let amountOfDecimals = numDigitsAfterDecimal(num);

                if (direction == 'reverse') {
                    $(this).text(num - now.toFixed(amountOfDecimals));
                } else {
                    $(this).text(now.toFixed(amountOfDecimals));
                }
            },
            complete: doCount
        });
    }

    function numDigitsAfterDecimal(x) {
        var afterDecimalStr = x.toString().split('.')[1] || ''
        return afterDecimalStr.length
    }
})
