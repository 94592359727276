window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('simplebar');
require('./vendor/match-height');
require('./vendor/wow');
require('./scripts/counter');
require('./scripts/cookie');
require('./scripts/event');
require('./scripts/custom');

import {Fef} from './classes/Fef';
import wow from './vendor/wow';

import {FormElements} from './classes/FormElements';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {
    /**
     * Initialise the Front-end Framework.
     *
     *
     * @type {Fef}
     */
    let fef = new Fef({
        resize: () => {
            rebind();
        }, matchHeightSite: () => {
            $('.news .item h3').matchHeight({
                byRow: true
            });
            $('.top__content .match').matchHeight({
                byRow: true
            });
            $('.contianerlessimages .match').matchHeight({
                byRow: true
            });
            $('.service__item .inner').matchHeight({
                byRow: true
            });
            $('.banner__contact .match').matchHeight({
                byRow: true
            });
            $('.quotes .matchquote').matchHeight({
                byRow: true
            });
            $('.overview--partners .partner__item .inner').matchHeight({
                byRow: true
            });
            $('.aspect-ratio').matchHeight({
                byRow: true
            });
        }
    });

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    //FAQ Toggle
    $('.faq .open-toggle').hide();
    $('.faq .open').show();
    $('.faq .closed').hide();
    $(".faq .toggle").click(function () {
        $(this).closest('.toggle-box').find(".open-toggle").slideToggle("slow", function () {
            if ($(this).closest('.toggle-box').hasClass('active')) {
                $(this).closest('.toggle-box').removeClass('active');
                $(this).closest('.toggle-box').find('.closed').hide();
                $(this).closest('.toggle-box').find('.open').show();
            } else {
                $(this).closest('.toggle-box').addClass('active');
                $(this).closest('.toggle-box').find('.closed').show();
                $(this).closest('.toggle-box').find('.open').hide();
            }
        });
    });

    // Close notification for 1 day
    if ($('.notification .inner').length > 0) {
        function notificationTopMargin() {
            if ($(window).outerWidth() > 0) {
                $('.notification').each(function () {
                    var paragraphHeight = $(this).outerHeight();
                    $('.page-wrapper').css({
                        'margin-top': paragraphHeight + 'px'
                    });
                    $('.header').css({
                        'top': paragraphHeight + 'px'
                    });
                });
            }
        }

        $('.js-notification-close').click(function () {
            Cookies.set('notification', false);

            $('.notification').hide();

            $('body').removeClass('has-notification');
            $('body').addClass('no-notification');
        });

        if (Cookies.get('notification') == 'false') {
            $('.notification').hide();

            $('body').addClass('no-notification');
            $('body').removeClass('has-notification');
        } else {
            $('.notification').show();

            $('body').addClass('has-notification');
            $('body').removeClass('no-notification');

            notificationTopMargin();
            $(window).resize(function () {
                notificationTopMargin();
            });
        }
    }

    $('.js-hero-slider .slick-dots').wrapAll('<div class="slider_navigation container"></div>')
    $('.js-hero-slider .slider_navigation').insertBefore(".paging__numbers");

    //wow IE11
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (!isIE11) {
        new wow().init();
    }

    //lines services
    $(window).scroll(function () {
        inViewport();
    });

    $(window).resize(function () {
        inViewport();
    });

    function inViewport() {
        $('.services, .services .services__blocks, .services .content__col, .services .content__col .line, .services .image__col, .aboutus, .footer, .photos').each(function () {
            var divPos = $(this).offset().top, topOfWindow = $(window).scrollTop();
            if (divPos > topOfWindow - 500) {
                $(this).addClass('visible');

            }
        });
    }

    //fade content in
    $(window).scroll(function () {
        var windowBottom = $(this).scrollTop() + $(this).outerHeight();


        $(".fadeIn").each(function () {
            var objectBottom = $(this).offset().top + 200;

            if (objectBottom < windowBottom) {
                if ($(this).css("opacity") == 0) {
                    $(this).fadeTo(400, 1);
                }
            } else {
                if ($(this).css("opacity") == 1) {
                    $(this).fadeTo(400, 1);
                }
            }
        });
    }).scroll();


    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    $(window).scroll(function () {
        $('.scrollable__devices').each(function () {
            if (isScrolledIntoView(this) === true) {
                $(this).addClass('visible');
            }
        });
    });


    function rebind() {
        $('[data-set-background-image-custom-hero]').each(function () {
            var imageUrl;
            if ($(window).outerWidth() <= fef.config.breakpoints.sm) {
                imageUrl = $(this).data('small');
            } else {
                imageUrl = $(this).data('normal');
            }
            $(this).css({
                'background-image': 'url(' + imageUrl + ')'
            });
        });
    }

    rebind();

    function referenceFilters() {
        //Filters toggle
        $(".filters__custom .togglefilter").click(function () {
            $(".filters__custom .togglefilter").not(this).siblings('.hidetoggle').removeClass("open");
            $(this).siblings(".hidetoggle").toggleClass("open");
        });

        //Filters active
        $(".filters__custom input:checked").each(function () {
            $(this).parent().addClass('active');
            $('.cases').addClass('active__filters');
        });
        //Filters set active onChange
        $(".filters__custom input").change(function () {
            $(this).parent().toggleClass('active');
        });

        //Filters mobile toggle
        $('.js-filterstoggle').off('click').on('click', function () {
            if ($('.filter__items').hasClass('active')) {
                $('.filter__items').removeClass('active');
                $('.js-filterstoggle').removeClass('reverse active');

            } else {
                $('.filter__items').addClass('active');
                $('.js-filterstoggle').addClass('reverse active');
                $('.js-filterstoggle').addClass('reverse active');
            }
            return false;
        });
    }

    referenceFilters();

    //bodyclass services detail
    if ($('.top__wrapper').length > 0) {
        $('body').removeClass('page');
    }

    if ($('.removebodypadding').length > 0) {
        $('body').addClass('page');
    }

    //Vacancy slider hover
    function vacancyslider() {
        $(".js-info__blocks-blue .block__item").hover(function () {
            $(this).toggleClass("active");
        });

        $('.js-info__blocks-blue .block__item .text p').each(function () {
            var paragraphHeight = $(this).outerHeight();
            $(this).parent().css({
                'margin-bottom': '-' + paragraphHeight + 'px'
            });
        });
    }

    vacancyslider();


    //Formulier
    $(".form input.checkbox:checked").each(function () {
        $(this).find('label').toggleClass('active');
    });

    if ($('input[name="input_59"]').is(':checked')) {
        $(this).toggleClass('active');
    }


    //scrollProgressBar
    function scrollProgressBar() {
        var getMax = function () {
            return $(document).height() - $(window).height();
        };

        var getValue = function () {
            return $(window).scrollTop();
        };

        var progressBar = $(".progress-bar"), max = getMax(), value, width;

        var getWidth = function () {
            // Calculate width in percentage
            value = getValue();
            width = (value / max) * 100;
            width = width + "%";
            return width;
        };

        var setWidth = function () {
            progressBar.css({width: getWidth()});
        };

        $(document).on("scroll", setWidth);
        $(window).on("resize", function () {
            // Need to reset max
            max = getMax();
            setWidth();
        });
    }

    scrollProgressBar();

    window.syvEvent.on('custom.filters::updated', referenceFilters);
    window.syvEvent.on('custom.items::updated(module:13)', function () {
        referenceFilters();
    });

})(jQuery);




